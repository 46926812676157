import React, { useState, useEffect } from 'react';
import './Register.scss';

const queryString = require('query-string');


function Register(props) {
  const [rules, setRules] = useState([]);
  const [existingUsers, setExistingUsers] = useState([]);
  const { uuid, email } = queryString.parse(props.location.search);

  const submitRegistration = () => {
    let username = document.querySelector('.username').value;
    let password = document.querySelector('.password').value;

    if(existingUsers.map(x=>x.username).includes(username)) {
      document.querySelector('.username').style.border = '3px solid red';
      document.querySelector('.Register__newaccount-input-warning').innerHTML = 'This username is taken';
      return;
    } else {
      document.querySelector('.Register__newaccount-input-warning').innerHTML = '';
    }
    
    if(!username) {
      document.querySelector('.username').style.border = '3px solid red';
      return;
    }
    if(!password) {
      document.querySelector('.password').style.border = '3px solid red';
      return;
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      body: JSON.stringify({ uuid, email, username, password }),
    };


    fetch('/registerapi/registernew', requestOptions)
      .then(response => response.json())
      .then((response) => {
          response = response.response;
          if(response === 'error') {
              setRules([]);
          } else if (response === 'registered') {
              window.location.replace('/');
          }
      });

  }

  useEffect(() => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      body: JSON.stringify({ uuid, email }),
    };


    fetch('/registerapi/rules', requestOptions)
      .then((response) => response.json())
      .then((row) => {
        setRules(row);
      });
    fetch('/registerapi/existingusers', requestOptions)
      .then((response) => response.json())
      .then((row) => {
        setExistingUsers(row);
      });
  }, [email, uuid]);

  return rules.length === 0 ? <span className="Register__error">Something has gone wrong with your invite. Email admin@matestapes.com</span>
    : (
      <div className="Register">
        <div className="Register__container">
          <div className="Register__rules">
            <h2 className="Register__rules-title">Rules</h2>
            <ol className="Register__rules-list">
              {rules.map((rule, i) => <li key={i}>{rule}</li>)}
            </ol>
          </div>
          <div className="Register__newaccount">
            <h2 className="Register__newaccount-title">Your new account</h2>
            <div className="Register__newaccount-row">
              <label className="Register__newaccount-label">Email:</label>
              <span className="Register__newaccount-input">{email}</span>
            </div>
            <div className="Register__newaccount-row">
              <label className="Register__newaccount-label">Username:</label>
              <input className="Register__newaccount-input username" /> <span className='Register__newaccount-input-warning' style={{color: 'red'}}></span>
              <span className="Register__newaccount-note">Must contain your actual name</span>
            </div>
            <div className="Register__newaccount-row">
              <label className="Register__newaccount-label">Password:</label>
              <input className="Register__newaccount-input password" type="password" name="password" />
            </div>
            <div className='Register__newaccount-submit' onClick={()=>submitRegistration()}>Register</div>
          </div>
        </div>
      </div>
    );
}

export default Register;
