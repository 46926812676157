import React, { useContext } from 'react';
import { UserContext } from '../App';
import UserName from '../UserName';

import './Footer.scss';

const Footer = (props) => {
    const { users } = props;
    const { thisUser } = useContext(UserContext);

    const logOut = () => {
        fetch('/auth/logout')
          .then(res => res.json())
          .then(() => {
            window.location.replace("/");
          });
    }
    const { movies } = props;
    return  <>
                <div className='Bottom'>
                    <div className='Footer__pusher'></div>
                    <div className='Footer'>
                        <div className='Footer__container'>
                            <div className='Footer__left'>
                                <div className='Footer__title'>Mates' Tapes</div>
                                <div className='Footer__content'>{movies.length} movies</div>
                                <div className='Footer__content'>{users.length} users</div>
                            </div>
                            <div className='Footer__right'>
                                <div className='Footer__title'>Logged in as {thisUser?<UserName allUsers={users} user_id={thisUser.user_id} />:''}</div>
                                <div className='Footer__logout'><span onClick={logOut}>Log Out</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
} 

export default Footer;
