
const postLog = (action_type, subject_id, subject_type) => {

	const submission = {
		action_type, 
		subject_id, 
		subject_type
	};

	const requestOptions = {
		method: 'POST',
		headers: { "Content-Type": "application/json; charset=utf-8" },
		body: JSON.stringify(submission)
	};

	fetch('/api/postlog', requestOptions);
};

export default postLog;
