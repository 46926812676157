/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect, useContext } from 'react';
import ReviewStars from './ReviewStars';
import NewReview from './NewReview';
import { UserContext } from '../App';
import UserName from '../UserName';
import moment from 'moment';
import Spinner from '../Spinner';

import './AllReviews.scss';

function AllReviews(props) {
  const [ editing, setEditing ] = useState(false);
  const [ reviews, setReviews ] = useState(props.thisMovie.reviews);
  const [ users, setUsers ] = useState([...props.users]);
  const { thisMovie, updateReviews, reviewString } = props;
  const { tmdb_id } = thisMovie;

  useEffect(() => {
    setReviews(thisMovie.reviews.filter(x=> x !== null).sort((a,b)=>moment(b.review_date) - moment(a.review_date)));
  }, [reviewString, thisMovie]);

  useEffect(() => {
    setReviews(props.thisMovie.reviews);
    setUsers([...props.users]);
  }, [props]);

  const { thisUser } = useContext(UserContext);
  const { user_id } = thisUser;

	let existingreview = null;

	if(reviews.find(x=>x.user_id === user_id)) {
		existingreview = reviews.find(x=>x.user_id === user_id);
	}

  return (
    users.length > 0 ? 
    <div className="AllReviews">
      <span className="AllReviews__title">Reviews</span>
      {reviews.length === 0 ? <span className="AllReviews__no-reviews">There are no reviews of this movie yet. Be the first:</span> : ''}
      {!reviews.find(x=>x.user_id===user_id)||editing?<NewReview setEditing={setEditing} updateReviews={updateReviews} tmdb_id={tmdb_id} existingreview={existingreview} />:''}
      {reviews.length > 0 ? reviews.map((review) => {
        if(!(editing === true && review.user_id === user_id)) {
          return (<div key={review.review_id} className="AllReviews__review">
            <div className="AllReviews__review-info">
              <span className="AllReviews__review-info-name"><UserName allUsers={users} user_id={review.user_id} /></span>
              <span className="AllReviews__review-info-date">{moment.utc(review.review_date).fromNow()}</span>
              <ReviewStars size="large" fill="white" stars={review.review_score} />
              {review.user_id===user_id?<span className='AllReviews__review-edit' onClick={()=>{setEditing(!editing)}}>Edit</span>:''}
            </div>
            <div className="AllReviews__review-text">
              {review.review_text.split('\n').map((p, key)=>{
                return<p key={`review_para_${review.review_id}_${p}`}>{p}</p>
              })}
            </div>
          </div>)} else {
            return null;
          }
      }) : ''}
    </div>
    :         
    <div style={{width: '10%', marginLeft: '45%', marginTop: '5%'}} >
      <Spinner />
    </div>

  );
}

export default AllReviews;
