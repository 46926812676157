import React from 'react';
import './Spinner.scss';

const Spinner = () => {
    return (        
        <div className="VHS">
            <div className="bigcircle rightbig"></div>
            <div className="bigcircle leftbig"></div>
            <div className="strut topstrut"></div>
            <div className="strut bottomstrut"></div>
            <div className="centerstrut"></div>
            <div className="centercircle centercircleleft"></div>
            <div className="centercircle centercircleright"></div>
            <div className="spool leftspool"></div>
            <div className="spool rightspool"></div>
        </div>
    )
}

export default Spinner;