const getUserScores = () => {
        const requestOptions = {
            method: 'GET',
            headers: { "Content-Type": "application/json; charset=utf-8" },
        };
        return fetch('/api/getUserScores', requestOptions)
            .then(res => {
                return res.json()
            })
            .then(data => {
                if(data.length > 0) {
                    data.reduce((prev, current) => (parseInt(prev.fetch_score) > parseInt(current.fetch_score)) && current.user_id !== 0 && prev.user_id !== 0 ? prev : current ).fetch_award = true;
                    data.reduce((prev, current) => (parseInt(prev.watch_score) > parseInt(current.watch_score)) && current.user_id !== 0 && prev.user_id !== 0 ? prev : current ).watch_award = true;
                    data.reduce((prev, current) => (parseInt(prev.review_score) > parseInt(current.review_score)) && current.user_id !== 0 && prev.user_id !== 0 ? prev : current ).review_award = true;
                }
                return data;
            });
};

export default getUserScores;
