
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import ReactPlayer from 'react-player';
import moment from 'moment';
import IMDBbox from './IMDBbox.js';
import ReportProblem from './ReportProblem.js';
import AllReviews from './AllReviews';
import UserName from '../UserName';
import postLog from '../__helpers/postLog';

import './PlayerPage.scss';

function PlayerPage(props) {
  let { tmdb_id } = useParams();
  const { updateReviews } = props;
  const [ movies, setMovies ] = useState([...props.movies].sort((a,b)=>parseInt(a.monthwatches-b.monthwatches)));
  const [ users, setUsers] = useState([])
  const [ thisMovie, setThisMovie ] = useState(movies.find((x) => { return x.tmdb_id === tmdb_id}));
  const [ showReport, setShowReport ] = useState(false);
  const [ thisProgress, setThisProgress ] = useState(0);
  const [ postStart, setPostStart ] = useState(false);
  const [ postComplete, setPostComplete ] = useState(false);

  useEffect(() => {
    setThisMovie(movies.find((x) => { return x.tmdb_id === tmdb_id}));
  }, [movies, tmdb_id]);

  useEffect(() => {
    setMovies([...props.movies]);
    setUsers([...props.users]);
  }, [props]);

  useEffect(() => {
    if(thisMovie) {
      if(thisProgress > 0 && !postStart) {
        setPostStart(true);
        postLog('watchstart', thisMovie.tmdb_id, 'movie')
      }
      if(Math.round(thisProgress*10)>8.5 && !postComplete) {
        setPostComplete(true);
        postLog('watchend', thisMovie.tmdb_id, 'movie')
      }
    };
  }, [thisProgress, postStart, postComplete, thisMovie]);

  const popularityString = () =>{
    if(thisMovie.watches < 1 ) {
      return '';
    } else {
      let sortedMovies = movies.sort((a,b) => {
        return parseInt(b.monthwatches - a.monthwatches);
      });
      let orderedNumber = sortedMovies.indexOf(sortedMovies.find(x=>x.tmdb_id===thisMovie.tmdb_id)) + 1;
      return `${thisMovie.watches} total watches. #${orderedNumber} this month ${orderedNumber<5?'🔥':''}`
    }
  }

  if (!thisMovie) return (<span>loading...</span>);

  return (
    <div className="PlayerPage">
      <div className='PlayerPage__container'>
        <span className="PlayerPage__title">{thisMovie.name} ({thisMovie.release_date.substring(0,4)})</span>
        <ReactPlayer
          url={thisMovie.video}
          key={tmdb_id}
          playing={false}
          onProgress={e => setThisProgress(e.played)}
          controls
          width='100%' 
          config={thisMovie.subtitles && thisMovie.subtitles.length>0?{file: {
            tracks: [
              {kind: 'subtitles', src: thisMovie.subtitles, srcLang: 'en', default: true}
            ]
          }}:{}}
        />
        <div className={`PlayerPage__report-issue ${showReport?'report-active':''}`}>
          <span className={`PlayerPage__report-issue-cta `} onClick={()=>{setShowReport(!showReport)}}>{showReport?'Close':'Report a problem with this movie'}</span>
          {showReport?<ReportProblem movie={thisMovie.name} setShowReport={setShowReport} />:''}
        </div>
        <div className="PlayerPage__info-row">
          <div className="PlayerPage__imdb-container"><IMDBbox rating={thisMovie.vote_average} /></div>
          <span className="PlayerPage__added">Fetched by <UserName user_id={thisMovie.added_by} /> {moment.utc(thisMovie.added_date).fromNow()}</span>
          <span className="PlayerPage__genres">{thisMovie.genres && thisMovie.genres.length > 0 ? thisMovie.genres.join(', ') : ''}</span>
        </div>
        <span className='PlayerPage__popularity'>{popularityString()}</span>
        <span className="PlayerPage__overview">{thisMovie.overview}</span>
        <AllReviews thisMovie={thisMovie} updateReviews={updateReviews} reviewString={JSON.stringify(thisMovie.reviews)} users={users} />
      </div>
    </div>
  );
}

export default PlayerPage;
