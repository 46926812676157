import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import getUserScores from './__helpers/getUserScores';
import Header from './Header';
import LogIn from './LogIn';
import Register from './Register';
import HomePage from './HomePage';
import AllMovies from './AllMovies';
import Stats from './Stats';
import Invite from './Invite';
import Help from './Help';
import PlayerPage from './PlayerPage';
import UserPage from './UserPage';
import Footer from './Footer';
import PostDevlog from './PostDevlog';
import CheckInvites from './CheckInvites';
import Devlog from './Devlog';
import Spinner from './Spinner';

import './App.scss';

export const UserContext = React.createContext();

export const App = () => {
  const [movies, setMovies] = useState([]);
  const [users, setUsers] = useState([]);

  const [authenticated, setAuthenticated] = useState(null);
  const [thisUser, setThisUser] = useState(null);

  const authCheck = () => {
    fetch('/auth/checkloggedin')
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 'loggedin') {
          setAuthenticated(true);
          setThisUser({ username: data.username, user_id: data.user_id });
        } else {
          setAuthenticated(false);
        }
      }).catch(console.log);
  };

  const updateReviews = (review) => {
    let mutableMovies = JSON.parse(JSON.stringify(movies));
    let thisMovie = mutableMovies.find(x => x.tmdb_id === review.tmdb_id);
    if (thisMovie.reviews.filter(x => x !== null).find(x => x.user_id === review.user_id)) {
      const index = thisMovie.reviews.indexOf(thisMovie.reviews.filter(x => x !== null).find(x => x.user_id === review.user_id));
      if (index > -1) {
        thisMovie.reviews.splice(index, 1);
      }
    }
    thisMovie.reviews.push(review);
    setMovies(mutableMovies);
  };

  useEffect(() => {
    authCheck();
  }, []);
  
  useEffect(() => {
    getNewData();
  }, [authenticated]);

  const getNewData = () => {
    if(authenticated) {
      fetch('/api/allmovies')
        .then((res) => res.json())
        .then((data) => {
          if (data.status !== null) {
            data.forEach(movie => {
              movie.reviews = movie.reviews.filter(x => x !== null);
            });
            setMovies(data);
          }
        })
        .catch(console.log);
    }
    const asyncGetUsers = async () => {
      setUsers(await getUserScores());
    };
    asyncGetUsers();
  }

  if (authenticated === false) {
    return (
      <Router>
        <Switch>
          <Route exact path="/login" component={LogIn} />
          <Route exact path="/register" component={Register}/>
          <Route path="/">
            <Redirect to="/login" />
          </Route>
        </Switch>
      </Router>
    );
  } if (authenticated === null) {
    return (null);
  }

  return (
    <>
      <UserContext.Provider
        value={{ thisUser }}
      >
        <Helmet>
          <title>Mates' Tapes</title>
        </Helmet>
        <Router>
          <div className={`App ${process.env.REACT_APP_ENVIRONMENT}`}>
            <Header thisUser={thisUser} />
            {
            movies.length > 0 && users.length > 0 && thisUser !== null ? 
              <Switch>
                <Route exact path="/">
                  <HomePage movies={movies} users={users} />
                </Route>
                <Route exact path="/postdevlog">
                  <PostDevlog />
                </Route>
                <Route exact path="/checkinvites">
                  <CheckInvites />
                </Route>
                <Route path="/allmovies">
                  <AllMovies movies={movies} users={users}/>
                </Route>
                <Route path="/watch/:tmdb_id">
                  <PlayerPage updateReviews={updateReviews} movies={movies} users={users} />
                </Route>
                <Route path="/user/:user_id">
                  <UserPage movies={movies} users={users} />
                </Route>
                <Route path="/stats">
                  <Stats />
                </Route>
                <Route path="/invite">
                  <Invite />
                </Route>
                <Route path="/help">
                  <Help />
                </Route>
                <Route path="/devlog">
                  <Devlog />
                </Route>
              </Switch>
              :
              <>
              </>
            }
            { users.length > 0 && movies.length > 0 && thisUser !== null ?
              <Footer users={users} movies={movies} />
              :
              <div style={{width: '10%', marginLeft: '45%', marginTop: '5%'}} >
                <Spinner />
              </div>
            }
          </div>
        </Router>
      </UserContext.Provider>
    </>
  );
}