import React, { useState, useEffect } from 'react';
import './Login.css';

function LogIn(props) {
    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');
    const [failedlogin, setfailedlogin] = useState(false);

    useEffect(() => {
        const query = new URLSearchParams(props.location.search);
        const q = query.get('q');
        if(q==='failedlogin') setfailedlogin(true);
    }, [props]);

    const myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        if(nam==='username') setemail(val);
        if(nam==='password') setpassword(val);
    }

    return  <div className="Login">
                <span className='Login__notice' style={{display:failedlogin?'block':'none'}}>Those login details didn't match. Email admin@matestapes.com if you forgot.</span>
                <div className="Login__container">
                    <form className="Login__form" action="auth/login_attempt" method="post">
                        <label htmlFor='username'><input name='username' id='username' onChange={myChangeHandler} placeholder="email"></input></label>
                        <label htmlFor='password'><input name='password' id='password' onChange={myChangeHandler} type='password' placeholder="password"></input></label>
                        <button className='Login__submit' type="submit" value="Submit">login</button>
                    </form>
                </div>
            </div>
}

export default LogIn;
