import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../App';
import './Help.scss';

function Help() {
    const [faqs, setFaqs] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const { thisUser } = useContext(UserContext);

    useEffect(() => {
        const fetchApi = async () => {
            fetch('/api/faq')
            .then(res => res.json())
            .then(data => {
                let sections = [];
                data.forEach(question => {
                    if(!sections.includes(question.section_title)) sections.push(question.section_title);
                })
                sections = sections.map(section=> {
                    return {
                        section,
                        questions: data.filter(x=>x.section_title===section)
                    }
                })
                setFaqs(sections)
            });
        }
        fetchApi();
    }, []);

    useEffect(() => {
        if(thisUser && thisUser.user_id === '0') setIsAdmin(true);
    }, [thisUser]);

    const postFaq = () => {

        let section = document.querySelector('.Help__admin-select').value;
        let question = document.querySelector('.Help__admin-question').value;
        let answer = document.querySelector('.Help__admin-answer').value;

        const submission = {
            section,
            question,
            answer
        };

        const requestOptions = {
            method: 'POST',
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify(submission)
        };

        fetch('/api/postfaq', requestOptions);
        window.reload();
    }

    return  <div className='Help'>
                <div className='Help__container'>
                    <span className='Help__contact'>Got a question not answered here? Email admin@matestapes.com</span>
                    {faqs.map((q,i)=> {
                        return  <div key={`section${i}`} className='Help__section'>
                                    <div className='Help__section-title'>{q.section}</div>
                                    {q.questions.map((faq,iq)=> {
                                        return  <div key={`${i}${iq}`} className='Help__section-entry'>
                                                    <div className='Help__section-entry-question'>
                                                        {faq.question}
                                                    </div>
                                                    <div className='Help__section-entry-answer'>
                                                        {faq.answer}
                                                    </div>
                                                </div>
                                    })}
                                </div>
                    })}
                    {isAdmin &&
                        <div className='Help__admin'>
                            <select className='Help__admin-select'>
                                {faqs.map((x,i)=><option key={`question${i}`} value={x.questions[0].id} >{x.section}</option>)}
                            </select>
                            <textarea className="Help__admin-question"></textarea>
                            <textarea className="Help__admin-answer"></textarea>
                            <div className="Help__admin-submit" onClick={()=>postFaq()}>submit</div>
                        </div>
                    }
                </div>
            </div>
}

export default Help;
