import React, { useState, useEffect, useRef } from 'react';
import './ReportProblem.scss';

function ReportProblem(props) {
  const { movie, setShowReport } = props;
  const [problem, setProblem] = useState(null);

  const textInput = useRef(null);

  useEffect(() => {
    textInput.current.focus();
  }, []);

  const submitProblem = () => {
    const submission = {
      subject: `Movie error report: ${movie}`,
      message: problem,
    };
    const requestOptions = {
      method: 'POST',
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: JSON.stringify(submission)
    };
    fetch('/mail/sendbrunomail', requestOptions)
      .then((response) => response.json());
    setShowReport(false);
  };
  const textChangeHandler = (e) => {
    setProblem(e.target.value);
  };

  const keyPressed = (e) => {
    if(e.key === 'Enter') submitProblem();
  };

  return (
    <div className="ReportProblem">
      <span className="ReportProblem__question">What is wrong with this movie?</span>
      <input type="text" className="ReportProblem__text-input" onChange={textChangeHandler} onKeyPress={keyPressed} ref={textInput} />
      <div className="ReportProblem__submit" onClick={submitProblem}>Submit</div>
    </div>
  );
}

export default ReportProblem;
