//name, email, howknow, suggestor
const insertInvite = (name, email, howknow, suggestor, username) => {

	const submission = {
		name, email, howknow, suggestor, username
	};
	const requestOptions = {
		method: 'POST',
		headers: { "Content-Type": "application/json; charset=utf-8" },
		body: JSON.stringify(submission)
	};

	fetch('/api/insertInvite', requestOptions);

};

export default insertInvite;
