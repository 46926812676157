import React, { useState, useEffect, useContext } from 'react';
import './NewReview.scss';
import SelectableStars from './SelectableStars';
import { UserContext } from '../App';
import UserName from '../UserName';
import postLog from '../__helpers/postLog';

function NewReview(props) {
  const [starsError, setStarsError] = useState(false);
  const [reviewError, setReviewError] = useState(false);
  const [stars, setStars] = useState(null);
  const [review, setReview] = useState(null);
  const { thisUser } = useContext(UserContext);
	const { username, user_id} = thisUser;
  const { tmdb_id, existingreview, updateReviews, setEditing } = props;
	
	useEffect(() => {
		if(!stars && existingreview) {
				setStars(existingreview.review_score);
		}
		if(!review && existingreview && existingreview.review_score) {
			setReview(existingreview.review_text);
		}
	}, [existingreview, review, stars]);

  const textareaOnChange = (e) => {
    setReview(e.target.value);
  };

	const submitReview = () => {
    if(stars === false || stars === null) {
      setStarsError(true)
      return;
    } else {
      setStarsError(false)
    }

    if(!review) {
      setReviewError(true)
      return;
    } else {
      setReviewError(false)
    }

		const submission = {
			tmdb_id,
			user_id,
			review_date: new Date().toUTCString(),
			review_score: stars,
			review_text: review,
			username,
		};

		const requestOptions = {
      method: 'POST',
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: JSON.stringify(submission)
    };

    fetch('/api/postreview', requestOptions)
      .then((response) => response.json())
      .then(row => {
        if(existingreview) {
          postLog('update', row.review_id, 'review');
        } else {
          postLog('insert', row.review_id, 'review');
        }
      });
    updateReviews(submission);
    setEditing(false);
  }

  return (
    <>
      <div className="NewReview">
        <div className="NewReview__review">
          <div className="NewReview__review-info">
            <span className="NewReview__review-info-name">
              <UserName user_id={user_id} />
            </span>
            <SelectableStars tmdb_id={tmdb_id} error={starsError} fill="white" setStars={setStars} stars={stars}/>
          </div>
          <div className="NewReview__review-text">
            <textarea className={`NewReview__review-text-input ${reviewError?'error':''}`} onChange={textareaOnChange} defaultValue={existingreview?existingreview.review_text:''}></textarea>
          </div>
        </div>
				<div className='NewReview__submit' onClick={submitReview}>{existingreview?'Save':'Submit'}</div>
      </div>
    </>
  );
}

export default NewReview;
