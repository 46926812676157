import React, { useState, useEffect } from 'react';
import './SelectableStars.scss';
import FullStar from './svg/FullStar.js';
import HalfStar from './svg/HalfStar.js';
import EmptyStar from './svg/EmptyStar.js';

function SelectableStars(props) {
  let { fill, setStars, stars, error } = props;

  const [internalStars, setInternalStars] = useState(0);
	const [decidedStars, setDecidedStars] = useState(false);
  useEffect(() => {
			if(stars) {
				setDecidedStars(stars);
				setInternalStars(stars);
			}
	}, [props, stars]);

	const starArray = [];

  const size = 36;
	let useStars = 0;
	if(!decidedStars || internalStars !== decidedStars) {
	  useStars = internalStars;
	} else {
		useStars = decidedStars;
	}

  if (useStars === 6) {
    starArray.push(<FullStar key={1+useStars} size={size} fill={fill} />);
    starArray.push(<FullStar key={2+useStars} size={size} fill={fill} />);
    starArray.push(<FullStar  key={3+useStars}size={size} fill={fill} />);
  } else if (useStars === 5) {
    starArray.push(<FullStar  key={4+useStars} size={size} fill={fill} />);
    starArray.push(<FullStar  key={5+useStars} size={size} fill={fill} />);
    starArray.push(<HalfStar  key={6+useStars} size={size} fill={fill} />);
  } else if (useStars === 4) {
    starArray.push(<FullStar key={7+useStars} size={size} fill={fill} />);
    starArray.push(<FullStar key={8+useStars} size={size} fill={fill} />);
    starArray.push(<EmptyStar key={9+useStars} size={size} fill={fill} />);
  } else if (useStars === 3) {
    starArray.push(<FullStar key={10+useStars} size={size} fill={fill} />);
    starArray.push(<HalfStar key={11+useStars} size={size} fill={fill} />);
    starArray.push(<EmptyStar key={12+useStars} size={size} fill={fill} />);
  } else if (useStars === 2) {
    starArray.push(<FullStar key={13+useStars} size={size} fill={fill} />);
    starArray.push(<EmptyStar key={14+useStars} size={size} fill={fill} />);
    starArray.push(<EmptyStar key={15+useStars} size={size} fill={fill} />);
  } else if (useStars === 1) {
    starArray.push(<HalfStar key={16+useStars} size={size} fill={fill} />);
    starArray.push(<EmptyStar key={17+useStars} size={size} fill={fill} />);
    starArray.push(<EmptyStar key={18+useStars} size={size} fill={fill} />);
  } else if (useStars === 0) {
    starArray.push(<EmptyStar  key={19+useStars} size={size} fill={fill} />);
    starArray.push(<EmptyStar key={20+useStars} size={size} fill={fill} />);
    starArray.push(<EmptyStar key={21+useStars} size={size} fill={fill} />);
  }

  const onMouseMove = (e) => {
    let starEqiuv = Math.ceil((e.clientX - e.currentTarget.getBoundingClientRect().left) / (e.currentTarget.getBoundingClientRect().width/6));
    if ((e.clientX - e.currentTarget.getBoundingClientRect().left) <= 8) starEqiuv = 0;
    setInternalStars(starEqiuv);
  };

	const onMouseLeave = () => {
		if(decidedStars) {
			setInternalStars(decidedStars)
		} else {
			setInternalStars(0);
		}
	}

	const onClick = () => {
		setDecidedStars(internalStars);
		setStars(internalStars)
	}

  return (
    <div className={`SelectableStars ${error?'error':''}`} onClick={onClick} onMouseMove={onMouseMove} onMouseLeave={onMouseLeave}>
      {starArray}
    </div>
  );
}

export default SelectableStars;
