import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone/moment-timezone';
import GridList from '../GridList';
import getDevlog from '../__helpers/getDevlog';
import ReviewStars from '../PlayerPage/ReviewStars';
import UserName from '../UserName';
import Spinner from '../Spinner';
import './HomePage.scss';

moment.tz.add('Europe/London|GMT BST BDST|0 -10 -20|0101010101010101010101010101010101010101010101010121212121210101210101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-2axa0 Rc0 1fA0 14M0 1fc0 1g00 1co0 1dc0 1co0 1oo0 1400 1dc0 19A0 1io0 1io0 WM0 1o00 14o0 1o00 17c0 1io0 17c0 1fA0 1a00 1lc0 17c0 1io0 17c0 1fA0 1a00 1io0 17c0 1io0 17c0 1fA0 1cM0 1io0 17c0 1fA0 1a00 1io0 17c0 1io0 17c0 1fA0 1a00 1io0 1qM0 Dc0 2Rz0 Dc0 1zc0 Oo0 1zc0 Rc0 1wo0 17c0 1iM0 FA0 xB0 1fA0 1a00 14o0 bb0 LA0 xB0 Rc0 1wo0 11A0 1o00 17c0 1fA0 1a00 1fA0 1cM0 1fA0 1a00 17c0 1fA0 1a00 1io0 17c0 1lc0 17c0 1fA0 1a00 1io0 17c0 1io0 17c0 1fA0 1a00 1a00 1qM0 WM0 1qM0 11A0 1o00 WM0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1tA0 IM0 90o0 U00 1tA0 U00 1tA0 U00 1tA0 U00 1tA0 WM0 1qM0 WM0 1qM0 WM0 1tA0 U00 1tA0 U00 1tA0 11z0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1o00 14o0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00|10e6')

function HomePage(props) {
    let movies = [...props.movies];
    let users = [...props.users].sort((a,b)=>b.score - a.score)
    const [allReviews, setAllReviews] = useState([]);
    const [devlog, setDevlog] = useState([]);
    const [popularMovies, setPopularMovies] = useState([]);
    const [allLog, setAllLog] = useState([]);

    useEffect(() => {
        let reviews = []
        movies.forEach(movie=>{
            movie.reviews.forEach(review=>{
                reviews.push(review);
            });
        });
        setAllReviews(reviews.sort((a,b)=> a.review_date > b.review_date ? -1 : 1).map(review=>({...review, name:movies.find( m => review.tmdb_id === m.tmdb_id).name})));
    }, []);

    useEffect(() => {
        setPopularMovies(movies.sort((a,b)=>parseInt(a.monthwatches)>parseInt(b.monthwatches)?-1:1).slice(0,6));
    }, []);

    useEffect(() => {
        const getTheDevlog = async () => {
            const devlog = await getDevlog();
            setDevlog(devlog);
        };
        getTheDevlog();
    }, []);

    useEffect(() => {
        let thisAllLog = [];
        devlog.forEach(devlog => {
            thisAllLog.push({
                type: 'devlog',
                date: devlog.date,
                data: devlog
            });
        });
        users.forEach(user => {
            thisAllLog.push({
                type: 'user',
                date: user.date_joined,
                data: user
            })
        });
        allReviews.forEach(review => {
            thisAllLog.push({
                type: 'review',
                date: review.review_date,
                data: review
            })
        });
        movies.forEach(movie => {
                        thisAllLog.push({
                            type: 'movie',
                            date: movie.added_date,
                            data: movie
                        })
                    })
        thisAllLog.sort((a,b)=> {
                        if (a.date > b.date) {
                                return -1;
                        }
                        if (b.date > a.date) {
                                return 1;
                        }
                        return 0;
                    });
        setAllLog(thisAllLog);
    }, [devlog, allReviews]);
		
	let momentString = '';

    return  <>
                <div className='HomePage'>
                    <div className='HomePage__container'>
                        <div className='HomePage__box HomePage__newarrivals'>
                            <div className='HomePage__box-title'>
                                New Arrivals
                            </div>
                            <GridList movies={movies.sort((a,b)=>{
                                if (a.added_date > b.added_date) {
                                    return -1;
                                }
                                if (b.added_date > a.added_date) {
                                    return 1;
                                }
                                return 0;
                            }).filter((x,i)=>i < 6)} query={''} />
                        </div>
                        <div className='HomePage__box HomePage__newreviews'>
                            <div className='HomePage__box-title'>
                                New Reviews
                            </div>
                            {allReviews.map((review,i)=>{
                                return <a key={`review${i}`} href={`/watch/${review.tmdb_id}`}><div className='HomePage__newreviews-review'>
                                    <span className='HomePage__newreviews-review-title'>{review.name}</span>
                                    <ReviewStars fill={'white'} stars={review.review_score} size={'large'} />
                                    <span className='HomePage__newreviews-review-username'>{review.username}</span>
                                </div></a>;
                            })}
                        </div>
                        <div className='HomePage__wide-box HomePage__devlog'>
                            <div className='HomePage__box-title'>
                                Recent Activity
                            </div>
                                {allLog.map((allItem,i) => {
                                    let thisDateString = moment.utc(allItem.date).tz('Europe/London').fromNow();
                                    let itemEntry = null;
                                    if(allItem.type === 'movie') {
                                        itemEntry = <div key={`alllog${i}`} className='HomePage__devlog-item-entry'><a href={`/watch/${allItem.data.tmdb_id}`} >New movie: {allItem.data.name} fetched by {allItem.data.username}</a></div>
                                    } else if (allItem.type === 'review') {
                                        itemEntry = <div key={`alllog${i}`} className='HomePage__devlog-item-entry'><a href={`/watch/${allItem.data.tmdb_id}`} >New review of {allItem.data.name} by {allItem.data.username}</a></div>
                                    } else if (allItem.type === 'devlog') {
                                        itemEntry = <div  key={`alllog${i}`} className='HomePage__devlog-item-entry HomePage__devlog-item-entry--devlog'><a href='/devlog'><div className='HomePage__devlog-item-entry--devlog-title'>Mates' Tapes v{allItem.data.version}</div><div className='HomePage__devlog-item-entry--devlog-text'>Read about this release</div></a></div>
                                    } else if (allItem.type === 'user') {
                                        itemEntry = <div  key={`alllog${i}`} className='HomePage__devlog-item-entry'><a href={`/user/${allItem.data.user_id}`}>{allItem.data.username} has joined Mates' Tapes.</a></div>
                                    }

                                    let thisItem = <div  key={`alllogEntry${i}`} className="HomePage__devlog-item">{thisDateString!==momentString?<span className='HomePage__devlog-item-date'>{thisDateString}</span>:''} {itemEntry}</div>;
                                    momentString = thisDateString;
                                    return thisItem;
                                })}
                        </div>
                        <div className='HomePage__box HomePage__users'>
                            <div className='HomePage__box-title'>
                                Users
                            </div>
                            {users.map((user,i)=>{
                                return  <div key={`userkey${i}`} className='HomePage__users-user'>
                                            <UserName user_id={user.user_id} allUsers={users} /><span className='HomePage__users-user-joined'>joined {moment(user.date_joined).fromNow()}</span>
                                        </div>;
                            })}
                        </div>
                        <div className='HomePage__box HomePage__trending'>
                            <div className='HomePage__box-title'>
                                Trending
                            </div>
                            <GridList movies={popularMovies} query={''} />
                        </div>
                    </div>
                </div>
            </>
}

export default HomePage;