import React, { useState, useEffect } from 'react';
import {  NavLink } from 'react-router-dom';
import './Header.css';

function Header(props) {
  const [thisUserId, setThisUserId] = useState(0);
  
  useEffect(() => {
    if(props.thisUser) setThisUserId(props.thisUser.user_id);
  }, [props]);
  
  return (
    <div className="Header">
      <h1 className="Header__title">MATES' TAPES</h1>
      <div className="Header__navigation">
        <NavLink exact className="Header__link" activeStyle={{ background: 'white', color: 'black' }} to="/"><span>HOME</span></NavLink>
        <NavLink className="Header__link" activeStyle={{ background: 'white', color: 'black' }} to="/allmovies"><span>ALL MOVIES</span></NavLink>
        <NavLink className="Header__link" activeStyle={{ background: 'white', color: 'black' }} to={`/user/${thisUserId}`}><span>MY PROFILE</span></NavLink>
        <NavLink className="Header__link" activeStyle={{  background: 'white', color: 'black' }} to="/invite"><span>INVITE SOMEONE</span></NavLink>
        <NavLink className="Header__link" activeStyle={{  background: 'white', color: 'black' }} to="/help"><span>HELP/FAQ</span></NavLink>
      </div>
    </div>
  );
}

export default Header;
