import React, { useState, useEffect, useContext } from 'react';
import insertInvite from '../__helpers/insertInvite';
import { UserContext } from '../App';
import './Invite.scss';

function Invite() {

    const [thanksText, setThanksText] = useState('');
    const [user_id, setUser_id] = useState(null);
    const [username, setUsername] = useState(null);
    const { thisUser } = useContext(UserContext);

    useEffect(() => {
        if(thisUser) {
            setUser_id(thisUser.user_id);
            setUsername(thisUser.username);
        }
    }, [thisUser]);

    const formInvite = () => {
        let name = document.querySelector('.Invite__form-name-input').value;
        let email = document.querySelector('.Invite__form-email-input').value;
        let howknow = document.querySelector('.Invite__form-howknow-input').value;
        document.querySelector('.Invite__form-name-input').style.border = 'none';
        document.querySelector('.Invite__form-email-input').style.border = 'none';
        document.querySelector('.Invite__form-howknow-input').style.border = 'none';
        if (!name) {
            document.querySelector('.Invite__form-name-input').style.border = '3px solid red';
            return;
        }
        if (!email) {
            document.querySelector('.Invite__form-email-input').style.border = '3px solid red';
            return;
        }
        if (!howknow) {
            document.querySelector('.Invite__form-howknow-input').style.border = '3px solid red';
            return;
        }
        insertInvite(name.trim(), email.trim(), howknow, user_id, username);
        setThanksText('Thanks for your suggestion, it will be reviewed by an admin.');
    }

    return  <div className='Invite'>
                <div className='Invite__container'>
                    <div className='Invite__explainer'>
                        If you think someone would make a good member of the Mates' Tapes community, you can recommend them for membership here. After you submit their name, an admin will have to approve the application. Then the invitee will be sent an invite to their email address.
                    </div>
                    {thanksText.length === 0 ?
                    <div className='Invite__form'>
                        <div className='Invite__form-name'>
                            <label className='Invite__form-name-label'>Name:</label> <input className='Invite__form-name-input' />
                        </div>
                        <div className='Invite__form-email'>
                            <label className='Invite__form-email-label'>Email:</label> <input className='Invite__form-email-input' />
                        </div>
                        <div className='Invite__form-howknow'>
                            <label className='Invite__form-howknow-label'>How do you know this person:</label> <textarea className='Invite__form-howknow-input'></textarea>
                        </div>
                        <div className='Invite__form-submit' onClick={()=>formInvite()}>submit</div>
                    </div>
                    :<span className='Invite__thankstext'>{thanksText}</span>}
                </div>
            </div>
}

export default Invite;