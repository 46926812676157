import React, { useState, useEffect } from 'react';
import getUserScores from '../__helpers/getUserScores';
import Medal from './svg/Medal.js';
import './UserName.scss';

function UserName (props) {
    const { user_id, allUsers } = props;
    const [ thisUserScore, setThisUserScore ] = useState({username:'',score:''});

    useEffect(() => {
        const getdata = async () => {
            const allUsers = await getUserScores();
            const thisUser = allUsers.find(x=>parseInt(x.user_id) === parseInt(user_id));
            if(parseInt(thisUser.user_id)===0) {
                thisUser.score = 'admin';
            };
            if(thisUser) setThisUserScore(thisUser);
        }
        if(!props.allUsers || props.allUsers.length < 1 || !props.allUsers.find(x=>parseInt(x.user_id) === parseInt(props.user_id))) {
            getdata();
        } else {
            const thisUser = props.allUsers.find(x=>parseInt(x.user_id) === parseInt(props.user_id));
            if(parseInt(thisUser.user_id)===0) {
                thisUser.score = 'admin';
            };
            if(thisUser) setThisUserScore(thisUser);
        }
    }, [props]);

    return (
        <div className="UserName">
                <a href={`/user/${thisUserScore.user_id}`} ><span className='UserName__name'>{thisUserScore.username}</span> <div className='UserName__score'>{thisUserScore.score}</div>
                { thisUserScore.fetch_award  ?  <Medal type={'fetch'} />  :  <></> }
                { thisUserScore.review_award  ?  <Medal type={'review'} />  :  <></> }
                { thisUserScore.watch_award  ?  <Medal type={'watch'} />  :  <></> }
                </a>
        </div>
    )
}
export default UserName;
