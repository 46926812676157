import React, { useState, useEffect } from 'react';
import getDevlog from '../__helpers/getDevlog';
import moment from 'moment';

import './Devlog.scss';

const Devlog = () => {
    const [devlog, setdevlog] = useState([]);

    useEffect(() => {
        const getLatestDevlog = async () => {
            let thisdevlog = await getDevlog();
            thisdevlog.sort((a,b) => parseFloat(b.version) - parseFloat(a.version))
            setdevlog(thisdevlog);
        }
        getLatestDevlog();
    }, []);
    return  <div className='Devlog'>
                <div className='Devlog__container'>
                    {devlog.map(log=> {
                        return  <div className='Devlog__log'>
                                    <span className='Devlog__log-title'>Mates Tapes' {log.version}</span>
                                    <span className='Devlog__log-date'>{moment(log.date).format('Do MMM YYYY')}</span>
                                    <span className='Devlog__log-text' dangerouslySetInnerHTML={{__html: log.text}}></span>
                                </div>
                    })}
                </div>
            </div>
}
export default Devlog;
