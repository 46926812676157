import React, { useState, useEffect } from 'react';
import getDevlog from '../__helpers/getDevlog';

import './PostDevlog.scss';

function PostDevlog() {
    const [existingDevlog, setExistingDevlog] = useState([]);
    useEffect(() => {
        const getTheDevlog = async () => {
            const devlog = await getDevlog();
            setExistingDevlog(devlog);
        };
        getTheDevlog();
    }, []);

    const submit = () => {

        let version = document.querySelector('#version').value;
        let text = document.querySelector('#text').value;

        const submission = {
			version,
            text
		};

        const requestOptions = {
            method: 'POST',
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify(submission),
        };


        fetch('/api/postDevlog', requestOptions)
        .then((response) => response.json())
        .then(row => {
            
        });
    }

    return(<div className='PostDevlog'>
                <div className='PostDevlog__container'>
                    {existingDevlog.map(entry => {
                        return <pre>{JSON.stringify(entry)}</pre>
                    })}
                    <label class='PostDevlog__input'>
                        Version:
                        <input id='version' />
                    </label>
                    <label class='PostDevlog__input'>
                        Text:
                        <textarea id='text'></textarea>
                    </label>
                    <div className='PostDevlog__submit' onClick={()=>submit()}>submit</div>
                </div>
            </div>)
}

export default PostDevlog;
