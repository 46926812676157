import React, {useState, useEffect} from 'react';
import { useInView } from 'react-intersection-observer';
import IMDBbox from '../PlayerPage/IMDBbox';
import ReviewStars from '../PlayerPage/ReviewStars';
import Spinner from '../Spinner'
import './GridList.scss';

function handleBoxClick(tmdb_id) {
  window.location.href = `/watch/${tmdb_id}`;
}

const nothingNotice = <span className="MainList__nothing-notice">We couldn't find what you were looking for. In Mates' Tapes v2, you will be able to fetch new films. For now email admin@matestapes.com and request it.</span>;

function GridList(props) {
  const [query, setQuery] = useState('');
  const [movies, setMovies] = useState([]);
  const [formattedMovies, setFormattedMovies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(24);
  const { ref, inView } = useInView({})

  useEffect(() => {
    setQuery((' ' + props.query).slice(1));
    setMovies([...props.movies]);
  }, [props]);

  useEffect(() => {
    if(inView) {
      setLoad(load => load+24);
    }
  }, [inView])

  useEffect(() => {
    setFormattedMovies(movies.slice(0,load).map((movie, i) => {
          let imageurl = `${movie.video.split('/')[1]}/${movie.video.split('/')[2]}/folder.jpg`;
          let avg = 0;
          if(movie.reviews.filter(x=>x!==null).length > 0) {
            let sum = 0;
            movie.reviews.forEach( r => sum += r.review_score);
            avg = sum / movie.reviews.length;
          };
          return (
            <div ref={i===load-1?ref:null} key={movie.tmdb_id} onClick={() => { handleBoxClick(movie.tmdb_id); }} className="GridList__box">
              <div className="GridList__box-contents">
                <img alt={movie.name} src={imageurl} />
                <div className="GridList__box-info">
                  <div className="GridList__box-stats">
                    <div className="GridList__box-IMDB"><IMDBbox rating={movie.vote_average} /></div>
                    <div className="GridList__box-country">{movie.original_language?movie.original_language.toUpperCase():''}</div>
                  </div>
                  <span className="GridList__box-title">{`${movie.name} (${movie.release_date.substring(0,4)})`}</span>
                </div>
              </div>
              <div className="GridList__overlay">
              </div>
              <span className="GridList__overlay-text">{movie.overview}</span>
              {movie.reviews.filter(x=>x!==null).length > 0?<div className="GridList__box-reviews"><ReviewStars fill='white' size='small' stars={Math.round(avg)} /><span className='GridList__box-reviews-figure'>{movie.reviews.length} review{movie.reviews.length > 1?'s':''}</span></div>:''}
            </div>
          );
        }))
      if(loading === true) {
        let thisTimer = setTimeout(() => {
          setLoading(false);
        }, 250);
      }
    }, [movies, load]);
  return (
    <div className="GridList">
      <div className="GridList__container">
        {
          (movies.length > 0 || query.length > 0) && !loading ?
            formattedMovies
          : 
          <div style={{width: '25%'}} >
            <Spinner />
          </div>
        }
        { movies.length === 0 && query.length > 0
          ? nothingNotice
          : null }
      </div>
    </div>
  );
}

export default GridList;