import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import ReviewStars from '../PlayerPage/ReviewStars';
import UserName from '../UserName';
import Spinner from '../Spinner';
import './UserPage.scss';


function UserPage(props) {

	const [thisUser, setThisUser] = useState(null);
	const [showMedalOne, setShowMedalOne] = useState(null);
	const [showMedalTwo, setShowMedalTwo] = useState(null);
	const [showMedalThree, setShowMedalThree] = useState(null);
	const [topFetcher, setTopFetcher] = useState(false);
	const [topReviewer, setTopReviewer] = useState(false);
	const [topWatcher, setTopWatcher] = useState(false);
	const [movies, setMovies] = useState([...props.movies]);
	const [userMovies, setUserMovies] = useState([]);
	const [userReviews, setUserReviews] = useState([]);
	const [users, setUsers] = useState([...props.users]);

	let { user_id } = useParams();

	useEffect(() => {
		setMovies([...props.movies]);
		setUsers([...props.users]);
		setThisUser([...props.users].find(x=>parseInt(x.user_id) === parseInt(user_id)))
	}, [props, user_id]);

	useEffect(() => {
		if(thisUser !== null) {
			if(thisUser.review_award) setTopReviewer(true);
			if(thisUser.watch_award) setTopWatcher(true);
			if(thisUser.fetch_award) setTopFetcher(true);
		}
	}, [thisUser]);

	useEffect(() => {
		setUserMovies(movies.filter(x=> x.added_by === user_id).sort((a,b)=>{
			if(b.added_date > a.added_date) {
				return 1;
			} else {
				return -1;
			}
		}));
		setUserReviews(movies.map(x=> {
			return x.reviews.filter(y=>y.user_id === user_id).map(z=>({title: x.name, review: z}));
		}).flat().sort((a,b) => {
			if(a.review.review_date > b.review.review_date) return -1;
			if(a.review.review_date < b.review.review_date) return 1;
			return 0;
		}))
	}, [movies, user_id]);

    return (
			thisUser && users.length > 0 ?
			<div className='UserPage'>
				<div className='UserPage__container'>
					<div className='UserPage__profile'>
						<div className='UserPage__profile-name'><UserName user_id={thisUser.user_id} /></div><div className='UserPage__profile-joined'> joined {moment(thisUser.date_joined).fromNow()}</div>{topFetcher?<div className='UserPage__medal topfetcher' onMouseMove={()=>setShowMedalOne(true)} onMouseOut={()=>setShowMedalOne(false)}>Gretchen Weiner Award<span className={`explainer ${showMedalOne?'shownexplainer':''}`} >{thisUser.username}'s fetched movies have been watched the most</span></div>:''}{topReviewer?<div className='UserPage__medal topreviewer' onMouseMove={()=>setShowMedalTwo(true)} onMouseOut={()=>setShowMedalTwo(false)}>Anton Ego Award<span className={`explainer ${showMedalTwo?'shownexplainer':''}`}>{thisUser.username} wrote the most reviews</span></div>:''}{topWatcher?<div className='UserPage__medal topwatcher'  onMouseMove={()=>setShowMedalThree(true)} onMouseOut={()=>setShowMedalThree(false)}>Lt. Archie Hicox Award<span className={`explainer ${showMedalThree?'shownexplainer':''}`}>{thisUser.username} watched the most movies</span></div>:''}
					</div>
					<div className='UserPage__score'>
						<div className='UserPage__score-total'><span>{(parseInt(thisUser.fetch_score) + parseInt(thisUser.review_score) + parseInt(thisUser.watch_score))} points</span></div>
						<div className='UserPage__score-subtotal'>
							<span className='UserPage__score-subtotal-text'>Fetched movies watched {parseInt(thisUser.fetch_score)/10} time{parseInt(thisUser.fetch_score)/10!==1?'s':''}</span>
							<span className='UserPage__score-subtotal-figure'>+{parseInt(thisUser.fetch_score)} points</span>
						</div>
						<div className='UserPage__score-subtotal'>
							<span className='UserPage__score-subtotal-text'>Wrote {parseInt(thisUser.review_score)/5} review{parseInt(thisUser.review_score)/5!==1?'s':''}</span>
							<span className='UserPage__score-subtotal-figure'>+{parseInt(thisUser.review_score)} points</span>
						</div>
						<div className='UserPage__score-subtotal'>
							<span className='UserPage__score-subtotal-text'>Watched {parseInt(thisUser.watch_score)} movie{parseInt(thisUser.watch_score)!==1?'s':''}</span>
							<span className='UserPage__score-subtotal-figure'>+{parseInt(thisUser.watch_score)} points</span>
						</div>
					</div>
					<div className='UserPage__details'>
						<div className='UserPage__fetched'>
							<span className='UserPage__fetched-title'>Movies fetched</span>
							{userMovies.length > 0 ? userMovies.map((x,i)=><span key={`fetched${i}`} className='UserPage__fetched-movie'><a href={`/watch/${x.tmdb_id}`}><span>{x.name} ({x.release_date.substring(0,4)})</span></a></span>):<div>{thisUser.username} hasn't fetched any movies yet</div>}
						</div>
						<div className='UserPage__reviews'>
							<span className='UserPage__reviews-title'>Movies reviewed</span>
							{userReviews.length > 0 ? userReviews.map((review) => {
								const title = review.title;
								review = review.review;
								return <div key={review.review_id} className="UserPage__review">
									<div className="UserPage__review-info">
										<span className="UserPage__review-info-title"><a href={`/watch/${review.tmdb_id}`}>{title}</a></span>
										<span className="UserPage__review-info-date">{moment(review.review_date).fromNow()}</span>
										<ReviewStars size="large" fill="white" stars={review.review_score} />
									</div>
									<div className="UserPage__review-text">
										{review.review_text}
									</div>
								</div>
							}):<div>{thisUser.username} hasn't reviewed anything yet</div>}
						</div>
					</div>
				</div>
			</div>
		:
			<div style={{width: '10%', marginLeft: '45%', marginTop: '5%'}} >
                <Spinner />
            </div>
	)
}

export default UserPage;