const getInvites = () => {
    const requestOptions = {
        method: 'GET',
        headers: { "Content-Type": "application/json; charset=utf-8" },
    };
    return fetch('/api/getInvites', requestOptions)
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;
        });
};

export default getInvites;
