import React, { useState, useEffect } from 'react';
import getInvites from '../__helpers/getInvites';

import './CheckInvites.scss';

function CheckInvites() {
    const [allInvites, setAllInvites] = useState([]);
    const [invitesSent, setInvitesSent] = useState(0);
    useEffect(() => {
        const getAllInvites = async () => {
            const theseInvites = await getInvites();
            theseInvites.sort((a,b)=>b.id - a.id);
            setAllInvites(theseInvites);
        };
        getAllInvites();
    }, [invitesSent]);

    const sendThisInvite = (invite) => {

        if(!invite.uuid) return;

        const submission = {
            uuid: invite.uuid
		};

        const requestOptions = {
            method: 'POST',
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify(submission),
        };


        fetch('/api/sendInvite', requestOptions)
            .then(() => setInvitesSent(invitesSent+1));

    }

    return(<div className='CheckInvites'>
                {allInvites.map(invite=>{
                    return  <>
                                <pre>{JSON.stringify(invite, null, 2)}</pre>
                                {!invite.sent?<div className='CheckInvites__submit' onClick={() => sendThisInvite(invite)}>invite</div>:''}
                            </>
                })}
            </div>);
}

export default CheckInvites;
