import React from 'react';
import './ReviewStars.scss';
import FullStar from './svg/FullStar.js';
import HalfStar from './svg/HalfStar.js';
import EmptyStar from './svg/EmptyStar.js';

function ReviewStars(props) {
    let { size, fill, stars } = props;
    if( size === 'large' ) {
        size = 36;
    } else if ( size === 'small' ) {
        size = 12;
    }

    let starArray = [];

    if(stars === 6) {
        starArray.push(<FullStar key={1} size={size} fill={fill} />);
        starArray.push(<FullStar key={2} size={size} fill={fill} />);
        starArray.push(<FullStar key={3} size={size} fill={fill} />);
    } else if(stars === 5) {
        starArray.push(<FullStar key={1} size={size} fill={fill} />);
        starArray.push(<FullStar key={2} size={size} fill={fill} />);
        starArray.push(<HalfStar key={3} size={size} fill={fill} />);
    } else if(stars === 4) {
        starArray.push(<FullStar key={1} size={size} fill={fill} />);
        starArray.push(<FullStar key={2} size={size} fill={fill} />);
        starArray.push(<EmptyStar key={3} size={size} fill={fill} />);
    } else if(stars === 3) {
        starArray.push(<FullStar key={1} size={size} fill={fill} />);
        starArray.push(<HalfStar key={2} size={size} fill={fill} />);
        starArray.push(<EmptyStar key={3} size={size} fill={fill} />);
    } else if(stars === 2) {
        starArray.push(<FullStar key={1} size={size} fill={fill} />);
        starArray.push(<EmptyStar key={2} size={size} fill={fill} />);
        starArray.push(<EmptyStar key={3} size={size} fill={fill} />);
    } else if(stars === 1) {
        starArray.push(<HalfStar key={1} size={size} fill={fill} />);
        starArray.push(<EmptyStar key={2} size={size} fill={fill} />);
        starArray.push(<EmptyStar key={3} size={size} fill={fill} />);
    } else if(stars === 0) {
        starArray.push(<EmptyStar key={1} size={size} fill={fill} />);
        starArray.push(<EmptyStar key={2} size={size} fill={fill} />);
        starArray.push(<EmptyStar key={3} size={size} fill={fill} />);
    }

    return  <div className='ReviewStars'>
                {starArray}
            </div>
}

export default ReviewStars;
