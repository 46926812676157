/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import MainList from '../MainList';
import GridList from '../GridList';
import Spinner from '../Spinner';
import './AllMovies.scss';

function AllMovies(props) {
  const cookies = new Cookies();
  const [allMovies, setAllMovies] = useState([]);
  const [movies, setMovies] = useState([]);
  const [query, setQuery] = useState('');
  const [allGenres, setAllGenres] = useState([]);
  const [genres, setGenres] = useState([]);
  const [allYears, setAllYears] = useState([]);
  const [year, setYear] = useState([null, null]);
  const [view, setView] = useState(false);
  const [sortMethod, setSortMethod] = useState(false);
  const [sortOrder, setSortOrder] = useState(false);
  const [showSortMenu, setShowSortMenu] = useState(false);

  useEffect(() => {
    setAllMovies([...props.movies])
  }, [props]);

  useEffect(() => {
    if(allMovies.length > 0) {
      let mergedGenres = [...new Set([].concat.apply([], allMovies.map(x=>x.genres)))].filter(x=>x && x.length>0);
      setAllGenres(mergedGenres);

      let earliestYear = new Date().getFullYear();

      allMovies.forEach(movie=>{
        if (parseInt(movie.release_date.substring(0, 4)) < earliestYear) earliestYear = parseInt(movie.release_date.substring(0, 4));
      })

      let theseAllYears = [];
      for (let index = new Date().getFullYear(); index >= earliestYear; index--) {
        theseAllYears.push(index);
      }
      setAllYears(theseAllYears);
    }
  }, [allMovies]);

  useEffect(() => {
    setView(cookies.get('view')?cookies.get('view'):'grid');
    setSortMethod(cookies.get('sortMethod')?cookies.get('sortMethod'):'added_date');
    setSortOrder(cookies.get('sortOrder')?cookies.get('sortOrder'):'desc');
  }, []);

  useEffect(() => {
    if(sortOrder && sortMethod) {
      setMovies(sortMovies(allMovies));
    }
  }, [sortOrder, sortMethod]);

  const setTheView = (newView) => {
    setView(newView);
    cookies.set('view', newView, { path: '/' });
  }
  
  const changeSort = (method, order) => {
    setShowSortMenu(false);
    setMovies([]);
    setSortMethod(method);
    setSortOrder(order);
    cookies.set('sortMethod', method, { path: '/' });
    cookies.set('sortOrder', order, { path: '/' });
  }

  const sortMovies = (movies) => {
    let method = sortMethod;
    let order = sortOrder;
    if(!sortMethod || !sortOrder) return movies;
    if(method === 'name') {
      movies.sort((a,b) => {
        if(order === 'desc') {
          if (a.name > b.name) {
              return 1;
          }
          if (b.name > a.name) {
              return -1;
          }
          return 0;
        } else {
          if (a.name > b.name) {
              return -1;
          }
          if (b.name > a.name) {
              return 1;
          }
          return 0;
        }
      })
    } else if (method === 'release_date') {
      movies.sort((a,b) => {
        if(order === 'desc') {
          if (a.release_date > b.release_date) {
              return -1;
          }
          if (b.release_date > a.release_date) {
              return 1;
          }
          return 0;
        } else {
          if (a.release_date > b.release_date) {
              return 1;
          }
          if (b.release_date > a.release_date) {
              return -1;
          }
          return 0;
        }
      })
    } else if (method === 'added_date') {
      movies.sort((a, b) => {
        if(order === 'desc') {
          if (a.added_date > b.added_date) {
              return -1;
          }
          if (b.added_date > a.added_date) {
              return 1;
          }
          return 0;
        } else {
          if (a.added_date > b.added_date) {
              return 1;
          }
          if (b.added_date > a.added_date) {
              return -1;
          }
          return 0;
        }
      })
    } else if (method === 'vote_average') {
      movies.sort((a, b) => {
        if(order === 'desc') {
          if (parseFloat(a.vote_average) > parseFloat(b.vote_average)) {
              return -1;
          }
          if (parseFloat(b.vote_average) > parseFloat(a.vote_average)) {
              return 1;
          }
          return 0;
        } else {
          if (parseFloat(a.vote_average) > parseFloat(b.vote_average)) {
              return 1;
          }
          if (parseFloat(b.vote_average) > parseFloat(a.vote_average)) {
              return -1;
          }
          return 0;
        }
      })
    } else if ('review_count') {
      movies.sort((a, b) => {
        if(order === 'desc') {
          if (a.reviews.length > b.reviews.length) {
              return -1;
          }
          if (b.reviews.length > a.reviews.length) {
              return 1;
          }
          return 0;
        } else {
          if (a.reviews.length > b.reviews.length) {
              return 1;
          }
          if (b.reviews.length > a.reviews.length) {
              return -1;
          }
          return 0;
        }
      })
    }
    return movies;
  }

  const getSortString = () => {
    let sortString = '';
    if(sortMethod === 'name') sortString += 'Name';
    if(sortMethod === 'release_date') sortString += 'Release Date';
    if(sortMethod === 'added_date') sortString += 'Date Added';
    if(sortMethod === 'vote_average') sortString += 'TMDB Rating';
    if(sortMethod === 'review_count') sortString += '# of Reviews';

    if(sortOrder === 'asc') sortString += ' Asc.';
    if(sortOrder === 'desc') sortString += ' Desc.';
    return sortString;
  }

  const resetFilters = () => {
    setQuery('');
    setGenres([]);
    setYear([null,null]);
  }

  const onChangeHandler = (event) => {
    let inputValue = '';
    console.log('filter change')

    if (event.target.type === 'text') {
      inputValue = event.target.value;
      setQuery(inputValue);
    }

    let newGenres = [...genres];

    if (event.target.type === 'checkbox') {
      if (genres.includes(event.target.value)) {
        newGenres = genres.filter((g) => g !== event.target.value);
        setGenres(newGenres);
      } else {
        const thisGenre = event.target.value;
        setGenres((genre) => [...genre, thisGenre]);
      }
    }

    console.log('set genres to ', newGenres);

    let yearStart = null;
    let yearEnd = null;

    if (event.target.type === 'select-one') {
      if (event.target.classList.contains('AllMovies__year-start')) {
        yearStart = event.target.value;
        setYear([yearStart, year[1]]);
      } else if (event.target.classList.contains('AllMovies__year-end')) {
        yearEnd = event.target.value;
        const newYear = [year[0], yearEnd];
        setYear(newYear);
      }
    }
  }

    useEffect(() => {
    let mutableMovies = [...allMovies];
    if (genres.length > 0) {
      mutableMovies = mutableMovies.filter((movie) => {
        let genreMatched = false;
        let numGenres = 0;
        genres.forEach((g) => {
          if (movie.genres && movie.genres.includes(g)) {
            genreMatched = true;
            numGenres += 1;
          }
        });
        movie.numGenres = numGenres;
        if(genreMatched) {
          console.log(movie.name, ' matched genres: ', genreMatched, numGenres)
        }
        return genreMatched;
      })
      mutableMovies.sort((a, b) => {
        return b.numGenres - a.numGenres;
      });
    }

    if (year[0] && year[0] !== 'null') {
      mutableMovies = mutableMovies.filter((x) => x.release_date.substring(0, 4) >= year[0]);
    }
    if (year[1] && year[1] !== 'null') {
      mutableMovies = mutableMovies.filter((x) => x.release_date.substring(0, 4) <= year[1]);
    }

    if (query && query.length > 0) {
      setMovies(sortMovies(mutableMovies.filter((movie) => movie.name.toUpperCase().includes(query.toUpperCase()))));
    } else {
      setMovies(mutableMovies);
    }
  }, [query, genres, year[0], year[1]]);

  const downArrow = () => {
    return (<svg version="1.1" id="sort_down_arrow" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10px" height="10px" viewBox="0 0 255 255" style={{fill:'white', marginLeft:'3px'}}>
              <g>
                <g id="arrow-drop-down">
                  <polygon points="0,63.75 127.5,191.25 255,63.75 		"/>
                </g>
              </g>
            </svg>)
  }

  return (
      <div className="AllMovies">
        <div className="AllMovies__filter-container">
          <div className="AllMovies__filters">
            <div className="AllMovies__filters-reset" onClick={()=> {resetFilters()}}>
              <span>Reset filters</span>
            </div>
            <div className="AllMovies__filter-input">
              <div className="AllMovies__filter-input-title">
                <label htmlFor="AllMovies__input">
                  Title:
                  <input className="AllMovies__input" name="AllMovies__input" onChange={onChangeHandler} value={query} />
                </label>
              </div>
              <div className="AllMovies__filter-input-genre">
                {allGenres.map((genre, i) => (
                  <label key={'genrelabel'+genre} htmlFor={`${genre}checkbox`}>
                    <input type="checkbox" key={i + genre} className="AllMovies__genre-checkbox" checked={genres.includes(genre) ? true : ''} name={`${genre}checkbox`} id={`${genre}checkbox`} value={genre} onChange={onChangeHandler} />
                    {genre}
                  </label>
                ))}
              </div>
              <div className="AllMovies__filter-input-year">
                <span className="AllMovies__filter-input-year-title">Year:</span>
                <div className="AllMovies__filter-input-year-container">
                  <span className="AllMovies__filter-input-year-label">Made before: </span>
                  <select className="AllMovies__year-end" value={year[1]===null?'null':year[1]} onChange={onChangeHandler}>
                    <option value="null">--</option>
                    {allYears.map((thisyear) => {
                      if(!year[0] || thisyear > parseInt(year[0])) 
                        return <option key={'optionstart'+thisyear} value={thisyear}>{thisyear}</option>
                      })}
                  </select>
                  <span className="AllMovies__filter-input-year-label">Made after: </span>
                  <select className="AllMovies__year-start" value={year[0]===null?'null':year[0]} onChange={onChangeHandler}>
                    <option value="null">--</option>
                    {allYears.map((thisyear) => {
                      if(!year[1] || thisyear < year[1])
                        return <option key={'optionend'+thisyear} value={thisyear}>{thisyear}</option>
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="AllMovies__view">
              <div className='AllMovies__view-type'>
                <span className={`AllMovies__view-option ${view === 'grid' ? 'active' : ''}`} onClick={() => { setTheView('grid'); }}>Grid</span>
                <span className={`AllMovies__view-option ${view === 'list' ? 'active' : ''}`} onClick={() => { setTheView('list'); }}>List</span>
              </div>
              <div onMouseMove={()=>setShowSortMenu(true)} onMouseOut={()=>setShowSortMenu(false)} className='AllMovies__view-sort'> 
                <span>Sorting: {getSortString()} {downArrow()}</span>
                <div className='AllMovies__view-sort-menu' style={{display:showSortMenu?'block':'none'}}>
                  <ul>
                    <li onClick={()=>{changeSort('name', 'desc')}} className={sortOrder==='name'&&sortMethod==='desc'?'active':''}>Name Desc.</li>
                    <li onClick={()=>{changeSort('name', 'asc')}} className={sortOrder==='name'&&sortMethod==='asc'?'active':''}>Name Asc.</li>
                    <li onClick={()=>{changeSort('release_date', 'desc')}} className={sortOrder==='release_date'&&sortMethod==='desc'?'active':''}>Release Date Desc.</li>
                    <li onClick={()=>{changeSort('release_date', 'asc')}} className={sortOrder==='release_date'&&sortMethod==='asc'?'active':''}>Release Date Asc.</li>
                    <li onClick={()=>{changeSort('added_date', 'desc')}} className={sortOrder==='date_added'&&sortMethod==='desc'?'active':''}>Date Added Desc.</li>
                    <li onClick={()=>{changeSort('added_date', 'asc')}} className={sortOrder==='date_added'&&sortMethod==='asc'?'active':''}>Date Added Asc.</li>
                    <li onClick={()=>{changeSort('vote_average', 'desc')}} className={sortOrder==='vote_average'&&sortMethod==='desc'?'active':''}>TMDB Rating Desc.</li>
                    <li onClick={()=>{changeSort('vote_average', 'asc')}} className={sortOrder==='vote_average'&&sortMethod==='asc'?'active':''}>TMDB Rating Asc.</li>
                    <li onClick={()=>{changeSort('review_count', 'desc')}} className={sortOrder==='review_count'&&sortMethod==='desc'?'active':''}># of Reviews Desc.</li>
                    <li onClick={()=>{changeSort('review_count', 'asc')}} className={sortOrder==='review_count'&&sortMethod==='asc'?'active':''}># of Reviews Asc.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        { movies.length > 0 || (query.length > 0 && movies.length === 0) ?
          view === 'grid'
            ? <GridList movies={movies} query={query}  />
            : <MainList movies={movies} query={query}  />
          :
            <div style={{width: '10%', marginLeft: '45%', marginTop: '5%'}} >
              <Spinner />
            </div>

          }
      </div>
  );
}

export default AllMovies;
