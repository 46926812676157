import React from 'react';
import './IMDBbox.scss'

function IMDBbox(props) {
    const colorScale = ['#f9ebea', '#f9ebe7', '#f9ebe4', '#f9ebe1', '#f9eade', '#f9eadb', '#f9ead8', '#f9ead5', '#f9ead2', '#f9eacf', '#f9e9cc', '#f9e9c9', '#f9e9c6', '#f9e9c3', '#f9e9c0', '#f9e9bd', '#f9e8ba', '#f9e8b6', '#f9e8b3', '#f9e8b0', '#f9e8ad', '#f9e8aa', '#f9e7a7', '#f9e7a4', '#f9e7a1', '#f9e79e', '#f9e69a', '#f9e596', '#f8e492', '#f8e38f', '#f8e28b', '#f8e187', '#f8e083', '#f7df7f', '#f7de7b', '#f7dd77', '#f7dd73', '#f7dc6f', '#f6db6c', '#f6da68', '#f6d964', '#f6d860', '#f6d75c', '#f5d658', '#f5d554', '#f5d450', '#f5d34d', '#f5d249', '#f4d145', '#f4d041', '#f0ce3f', '#e7cb40', '#dec840', '#d6c540', '#cdc241', '#c4bf41', '#bcbc42', '#b3b942', '#abb642', '#a2b343', '#99b043', '#91ad44', '#88aa44', '#7fa744', '#77a345', '#6ea045', '#659d46', '#5d9a46', '#549746', '#4b9447', '#439147', '#3a8e48', '#318b48', '#298848', '#208549', '#1e8348', '#1d8147', '#1d7f46', '#1c7e46', '#1c7c45', '#1c7a44', '#1b7943', '#1b7742', '#1a7541', '#1a7340', '#1a723f', '#19703e', '#196e3d', '#186d3c', '#186b3b', '#18693a', '#176839', '#176639', '#166438', '#166237', '#166136', '#155f35', '#155d34', '#145c33', '#145a32'];
    let colorRef = Math.floor((props.rating/10)*100);

    let bgcolor = colorScale[colorRef-1];
        return (
            <div className="ratingBox" style={{backgroundColor:bgcolor}} >
                <span>{props.rating}</span>
            </div>
        )
}

export default IMDBbox