/* eslint-disable camelcase */
import React, {useEffect, useState} from 'react';
import Moment from 'react-moment';
import ReviewStars from '../PlayerPage/ReviewStars';
import Spinner from '../Spinner'
import './MainList.css';

function handleRowClick(tmdb_id) {
  window.location.href = `/watch/${tmdb_id}`;
}

function AllMovies(props) {
  const { query } = props;
  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setMovies([...props.movies]);
    if(loading === true) {
      let thisTimer = setTimeout(() => {
        setLoading(false);
      }, 250);
    };
  }, [props]);

  const nothingNotice = <span className="MainList__nothing-notice">We couldn't find what you were looking for. In Mates' Tapes v2, you will be able to fetch new films.</span>

  return (
    <>
    {(movies.length > 0 || query.length > 0) && !loading ?
    <div className="MainList">
      <table className="MainList__main-table">
        <thead>
          <tr>
            <th className='titleCell'>Title</th>
            <th>Reviews</th>
            <th>Genres</th>
            <th>IMDB</th>
            <th>Added</th>
          </tr>
        </thead>
        <tbody>
          {movies.map((item, i) => {

            let reviewsAvg = 0
            item.reviews = item.reviews.filter(x=>x!==null);
            item.reviews.forEach(r=>{reviewsAvg+=r.review_score});
            reviewsAvg = reviewsAvg / item.reviews.length;
            return <tr key={i} onClick={() => { handleRowClick(item.tmdb_id); }}>
              <td className='titleCell'>
                {item.name}
                {' '}
(
                {item.release_date.substr(0, 4)}
)
              </td>
              <td className='GeraldineTheReviewCell'><ReviewStars size={'small'} fill={'white'} stars={Math.round(reviewsAvg)}/> {item.reviews.length>0?`${item.reviews.length} review${item.reviews.length>1?'s':''}`:''}</td>
              <td>{item.genres && item.genres.length > 0 ? item.genres.join(', ') : ''}</td>
              <td>{item.vote_average}</td>
              <td><Moment fromNow>{item.added_date}</Moment></td>
            </tr>
          })}
          { movies.length === 0 && query.length > 0
            ? nothingNotice
            : null }
        </tbody>
      </table>
    </div>
    :
    <div style={{width: '10%', marginLeft: '45%', marginTop: '5%'}} >
      <Spinner />
    </div>
  }</>
  );
}
export default AllMovies;
